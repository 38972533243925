import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row } from 'react-bootstrap'
import CustomSelect from '../../../components/CustomSelect'
import { family } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useQuery } from '@tanstack/react-query';
import { handleDemandBillView, handleDemandBillViewByFamily } from '../../../helper/print-demand-bill';
import { MONTHS } from '../../../helper/constants';
import WardDetailsModal from '../family/ward-details-modal';

function DemandBill() {
    const currentDate = new Date();
    const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
    const { data: families = [], isLoading, isError, error } = useQuery({
        queryKey: ['families'],
        queryFn: () => family.getAll(),
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
        select: (data) => data.data.family.filter(f => f.wards.length > 0)
    });
    useEffect(() => {
        if (isError) swal.error(error.response ? error.response.data.message : error.message)
    }, [isError, error])

    const familyColumns = useMemo(() => [
        {
            Header: "S.No",
            Cell: (cell) => cell.row.index + 1
        },
        {
            Header: "Father's Name",
            accessor: "father_name"
        },
        {
            Header: "Mother's Name",
            accessor: "mother_name"
        },
        {
            Header: "Phone",
            accessor: "father_mobile"
        },
        {
            Header: "wards",
            HeaderClass: "text-center",
            DataClass: "text-center",
            Cell: (cell) => {
                return <Badge bg="soft-danger">{cell.row.original.wards.length}</Badge>
            }
        },
        {
            Header: "Action",
            HeaderClass: "text-center",
            DataClass: "d-flex justify-content-center",
            Cell: (cell) => {
                return (
                    <div className="d-flex gap-3 bg-light p-2 rounded" style={{
                        width: 'fit-content'
                    }}>
                        <WardDetailsModal familyDetails={cell.row.original} />
                        <button className='btn btn-sm btn-primary'>Send SMS</button>
                        <button 
                            className='btn btn-sm btn-soft-warning' 
                            onClick={() => handleDemandBillViewByFamily(selectedMonth,document.querySelector('#upi-id').value,cell.row.original.id)}
                        >Print Demand Bill</button>
                    </div>
                )
            }
        },
        {
            Header: "List",
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-1">
                                <span className="text-dark">Family ID: #{row.id}</span>
                            </h5>
                            <p className="text-muted mb-0">
                                <span className="me-2 d-block d-md-inline">Father: {row.father_name}</span>
                                <span className="d-block d-md-inline">Mother: {row.mother_name}</span>
                                <p>Phone : {row.father_mobile}</p>
                            </p>
                            <span className="badge bg-soft-danger">
                                Wards: {row.wards.length}
                            </span>
                        </div>
                        <div className="flex-shrink-0 p-2 bg-light rounded">
                            <div className="d-grid gap-2">
                                <WardDetailsModal familyDetails={row} />
                                <button className='btn btn-sm btn-primary'>
                                    Send SMS
                                </button>
                                <button onClick={() => handleDemandBillViewByFamily(selectedMonth,document.querySelector('#upi-id').value,row.id)} className='btn btn-sm btn-soft-warning'>
                                    Print Demand Bill
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }

    ], [families])
    return (
        <div>
            <Card>
                <CardHeader>
                    <CardTitle className='d-flex justify-content-between'>
                        <h3>Demand Bill By Family</h3>
                        <button className="btn btn-sm btn-primary px-2 py-0 d-flex align-items-center gap-1 font-13">
                            <i className="bx bx-cog m-0"></i> Settings
                        </button>
                    </CardTitle>
                    <CardSubtitle className='mt-3'> In this section, you can Generate, Send demand bill sms or Print Demand bill for students </CardSubtitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={3} className="mb-2">
                            <CustomSelect
                                onChange={e => {
                                    setSelectedMonth(e.value);
                                }}
                                defaultValue={MONTHS.filter(i => i.value === currentDate.getMonth())}
                                options={MONTHS} placeholder="Select Month"
                            />
                        </Col>
                        <Col lg={3} className='mb-2'>
                            <input type="text" className='form-control' id='upi-id' placeholder='Enter UPI ID' />
                        </Col>
                        <Col lg={3} className="mb-2">
                            <button className='btn btn-soft-danger w-100 d-flex justify-content-center align-items-center'>
                                <i class="ri-mail-send-fill my-0 py-0 me-2"></i>
                                Send Demand Bill SMS
                            </button>
                        </Col>
                        <Col lg={3} className="mb-2">
                            <button
                                className='btn btn-soft-warning w-100 d-flex justify-content-center align-items-center'
                                onClick={() => handleDemandBillView(selectedMonth,document.querySelector('#upi-id').value)}
                            >
                                <i className="bx bxs-file-pdf my-0 py-0 me-2"></i>
                                Generate Demand Bill
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <TableResponsive
                        data={families}
                        columns={familyColumns}
                        isLoading={isLoading}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default DemandBill