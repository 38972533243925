import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, course, student } from '../../../../helper/api_url';
import { swal } from '../../../../helper/swal';
import { TableResponsive } from '../../../../components/common/TableResponsive';
import { FeeButton } from '../../Accounts/FeeButton';
import { EditStudentModal } from './EditStudentistModal';
import UpdateFamilyModel from './UpdateFamilyModel';
import { Button } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Swal from 'sweetalert2';
import ChangeCourseModal from './ChangeCourseModal';

function StudentList(props) {
    const { course_id, activeTab } = props;
    const queryClient=useQueryClient();
    const {data:studentList=[],isLoading:studentListLoading}=useQuery({
        queryKey:['course_students',course_id],
        queryFn:()=>course.students(course_id),
        select:(data)=>data.data.student,
        cacheTime:1800000,
        staleTime:1800000
    })
    const handleDelete=(id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                student.delete(id).then(res=>{
                    queryClient.invalidateQueries(['course_students', course_id])
                    swal.success(res.data.message)
                }).catch(err=>swal(err.response?.data?.message||err.message))
            }
        })
    }
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass: '',
            DataClass: '',
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        { Header: "Gender", accessor: "gender", Cell: cell => { return (<p className="">{cell.row.original.gender.toUpperCase()}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "DOB", accessor: "dob", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Email", accessor: "email", HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Phone", accessor: "phone", HeaderClass: 'text-center', DataClass: 'text-center' },
        {
            Header: "Action", Cell: (cell) => {return (
            <div className='d-flex gap-2'>
                <Button onClick={()=>handleDelete(cell.row.original.id)} className='btn btn-sm btn-soft-danger add-btn waves-effect mx-1'>
                    <i className="ri-delete-bin-fill align-bottom"></i>
                </Button>
                <EditStudentModal data={cell.row.original} course_id={course_id} />
                <FeeButton id={cell.row.original.id}>
                    <i className="ri-coins-line align-bottom"></i>
                </FeeButton>
                <UpdateFamilyModel data={cell.row.original}  />
                <ChangeCourseModal studentData={cell.row.original} />
            </div>
            )}
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const imageUrl = ASSET_URL + row.avatar;
                return (
                    <>
                        <div className="d-flex">
                            <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                            <div className="flex-grow-1" data-id="1">
                                <h5 className="fs-13 mb-1">
                                    <a href="#" className="link text-dark"></a>
                                    <a href="#">{row.first_name} {row.last_name}</a>
                                </h5>
                                <p className="text-muted mb-0"><a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a> | <i className='bx bx-mail-send' />{row.email}</p>
                            </div>
                            <div className="flex-shrink-0">
                                <Button onClick={()=>handleDelete(row.id)} className='btn btn-sm btn-soft-danger add-btn waves-effect mx-1'>
                                    <i className="ri-delete-bin-fill align-bottom"></i>
                                </Button>
                                <EditStudentModal data={row} course_id={course_id}  />
                                <FeeButton id={row.id}>
                                    <i className="ri-coins-line align-bottom"></i>
                                </FeeButton>
                                
                            </div>
                        </div>
                        <div className='d-flex gap-2 justify-content-end'>
                            <UpdateFamilyModel data={row}  />
                            <ChangeCourseModal studentData={row} />
                        </div>
                    </>
                )
            }
        }
    ], [])
    return (
        <TableResponsive isLoading={studentListLoading} columns={columns} data={studentList} />
    )
}

export default StudentList