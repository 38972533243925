import { Modal, Form, Button } from "react-bootstrap"
import { useModalHandler } from "../../../../helper/custom_hook";
import { ASSET_URL, course, student } from "../../../../helper/api_url";
import { useQuery } from "@tanstack/react-query";
import CustomSelect from "../../../../components/CustomSelect";
import { useDispatch } from "react-redux";
import { swal } from "../../../../helper/swal";
import { setPreloader } from "../../../../features/Ui/uiSlice";


const ChangeCourseModal=({studentData})=>{
    const {status, setStatus,toggleModal} = useModalHandler();
    const dispatch=useDispatch();
    const {data:courses=[],isLoading,isError}=useQuery({
        queryKey:['courses'],
        queryFn:()=>course.list(),
        select : data=>data.data.course,
        cacheTime:1800000,
        staleTime:1800000,
    })
    const handleSubmit=(e)=>{
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'Updating...' }));
        const formData=new FormData(e.target);
        student.changeCourse(formData).then(res=>{
            swal.success(res.data.message);
            toggleModal();
        }).catch(err=>swal.error(err.response ? err.response.data.message : err.message))
        .finally(()=>dispatch(setPreloader({ loader: false, message: '' })))
    }
    
    return(
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                Change Course
            </button>
            <Modal show={status} onHide={toggleModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change Course</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <input type="hidden" name="student_id" value={studentData?.id} />
                    <Modal.Body>
                        <div className="mb-4">
                            <h6 className="text-muted mb-2">Student</h6>
                            <div className="d-flex align-items-center">
                                {studentData?.avatar && (
                                    <img 
                                        src={ASSET_URL + studentData.avatar} 
                                        alt="Student" 
                                        className="rounded-circle me-2"
                                        style={{ width: "40px", height: "40px", objectFit: "cover" }}
                                    />
                                )}
                                <h5 className="mb-0">{studentData?.first_name} {studentData?.last_name}</h5>
                            </div>
                        </div>

                        <div className="mb-4">
                            <h6 className="text-muted mb-2">Current Course</h6>
                            <div className="p-2 bg-light rounded">
                                <i className="ri-book-mark-line me-2"></i>
                                {studentData?.courses?.find(i=>i.pivot.status==='active')?.name || 'No course assigned'}
                            </div>
                        </div>

                        <Form.Group>
                            <Form.Label>
                                <h6 className="mb-2">Select New Course</h6>
                            </Form.Label>
                            <CustomSelect 
                                isSearchable
                                options={courses.map(item=>({label:item.name,value:item.id}))}
                                placeholder="Choose a course..."
                                name="course_id"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={toggleModal}>Cancel</Button>
                        <Button type="submit" className="btn btn-primary">Change Course</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
export default ChangeCourseModal;