import LZString from 'lz-string';

const viewDemandBill = (url,params={}) => {
    const token = LZString.compressToEncodedURIComponent(localStorage.getItem("_token"));
    const schoolCode = encodeURIComponent(window.__school_code);
    url = `${url}?&s=${schoolCode}&t=${token}`;
    Object.keys(params).forEach(key => {
        if(params[key]) url += `&${key}=${params[key]}`;
    });
    window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=800,height=600');
}

export const  handleDemandBillView = (month,upiId) => {
    let url = `https://management.unboxschool.in/printer/demand-bill/`;
    viewDemandBill(url,{m:month+1,u:upiId});
}

export const  handleDemandBillViewByFamily = (month,upiId,familyId) => {
    let url = `https://management.unboxschool.in/printer/demand-bill/`;
    viewDemandBill(url,{m:month+1,u:upiId,f:familyId});
}

export const handleDemandBillViewByClass = (month,upiId,classId) => {
    let url = `https://management.unboxschool.in/printer/demand-bill/by-class.php`;
    viewDemandBill(url,{m:month+1,u:upiId,c:classId});
}

export const handleDemandBillViewByStudent = (month,upiId,studentId) => {
    let url = `https://management.unboxschool.in/printer/demand-bill/`;
    viewDemandBill(url,{m:month+1,u:upiId,s:studentId});
}

