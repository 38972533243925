import React, { useEffect, useMemo, useState } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import {ASSET_URL, examination} from '../../../helper/api_url'
import { UpdateQuestionModal } from './Modals/QuestionModals/UpdateQuestionModal';
import { ViewQuestionModal } from './Modals/QuestionModals/ViewQuestionModal';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { AddQuestionModal } from './Modals/QuestionModals/AddQuestionModal';
import { swal } from '../../../helper/swal'
import ImageViewer from '../../../components/ImageViewer'
import copyImage from '../../../assets/images/schoolCopy/copypage.png'
function QuestionBank() {
    const dispatch = useDispatch();
    const [tableData,setTableData] = useState([]);
    useEffect(() => {
        examination.readQuestions()
                    .then(res => setTableData(res.data.question))
                    .catch(err => swal.error(err.response?.data?.message || err.message));
            }, []);
    const handleDelete = (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete: " + data.course_name,
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setPreloader({ loader: true, message: 'Deleting, please wait...' }));
                examination.deleteQuestion(data.id)
                    .then(res => {
                        setTableData(tableData.filter(td => td.id !== data.id));
                        Swal.fire('Deleted!', res.message, 'success');
                    })
                    .catch(err => {
                        Swal.fire('Error!', err.response ? err.response.data.message : err.message, 'error');
                    })
                    .finally(() => {
                        dispatch(setPreloader({ loader: false, message: '' }));
                    });
            }
        });
    };
        
    const columns = useMemo(()=>[
        {
            Header: "Course name",
            accessor: "course.name",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Subject",
            accessor: "subject",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Question",
            accessor: "question",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl = cell.row.original.image===null?copyImage:ASSET_URL + cell.row.original.image;
                return (
                    <ImageViewer image={imageUrl}>
                        <span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.question} </span>
                    </ImageViewer>
            )
            }
        },
        {
            Header: "Created Name",
            accessor: "creator_name",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
              return ( 
                <div className='widthFix'>
                  
                    <ViewQuestionModal 
                    data={cell.row.original}
                      />
                    <UpdateQuestionModal
                    data={cell.row.original} 
                    setTableData={setTableData}
                     />
               
                     
                    <Button 
                    onClick={()=> handleDelete(cell.row.original)} 
                        className="btn btn-sm btn-soft-danger me-1" >
                        <i className="ri-delete-bin-fill" />  
                    </Button>
                </div>
              )
            },
        }
    ]);
    return (
        <>
            <BreadCrumb title="Question Bank" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Question Bank</h5>
                            <AddQuestionModal  setTableData={setTableData} />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={true} columns={columns} data={tableData} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default QuestionBank