import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import ModalProfileImage from '../../../../../components/common/ModalProfileImage';
import { useModalHandler } from '../../../../../helper/custom_hook';
import ImageViewer from '../../../../../components/ImageViewer';
import { ASSET_URL } from '../../../../../helper/api_url';

export function ViewQuestionModal(data) {
    const { toggleModal, status } = useModalHandler();
    const navigate = useNavigate();



    return (
        <>
            {data.children ? (
                <div onClick={toggleModal}>{data.children}</div>
            ) : (
                <button onClick={toggleModal} className='btn btn-sm btn-soft-info me-1'>
                    <i className="ri-eye-fill" />
                </button>
            )}
            <Modal className="fade" centered={true} show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>View ({data?.data?.course?.name}) Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='px-2 text-center mt-2'>
                        <Col xs={12} >
                            <ul className="list-group">
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Class Name </span></Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data?.data?.course?.name} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Subject </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data?.data?.subject} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Question </span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data?.data?.question} </span>
                                        </Col>
                                    </Row>
                                </li>
                                <li className='list-group-item'>
                                    <Row>
                                        <Col xs={5}><span className='fw-bold'>Create By</span> </Col>
                                        <Col xs={2}><span className='fw-bold'>:</span></Col>
                                        <Col className='text-start' xs={5}>
                                            <span className='text-nowrap'> {data?.data?.creator_name} </span>
                                        </Col>
                                    </Row>
                                </li>
                                {
                                    data?.data?.image===null?null:
                                  
                                <li className='list-group-item'>
                                    <Row>
                                        <ImageViewer image={ASSET_URL+data?.data?.image}>
                                            <span> <img className="me-2 " style={{ objectFit: 'cover',height:'100px',width:'100px'}} src={ASSET_URL+data?.data?.image} alt="User Avatar" /> </span>
                                        </ImageViewer>
                                    </Row>
                                </li>
                                }

                            </ul>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </>
    );
}
