import { Card, Col, Modal, Row } from "react-bootstrap"
import { useModalHandler } from "../../../helper/custom_hook"
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { family } from "../../../helper/api_url";
import { swal } from "../../../helper/swal";
import DataLoading from "../../../components/DataLoading";

const WardDetailsModal = ({ familyDetails }) => {
    const { status, toggleModal } = useModalHandler();
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const handleRemoveWard = (ward_id,family_id) =>{
        setLoading(ward_id);
        family.removeWard(ward_id,family_id).then(res => {
            setLoading(false);
            swal.success(res.message);
            queryClient.setQueryData(['families'], (oldData) => {
                if (!oldData) return oldData;
                return {
                    ...oldData,
                    data: {
                        ...oldData.data,
                        family: oldData.data.family.map(f => f.id === family_id ? { ...f, wards: f.wards.filter(w => w.id !== ward_id) } : f)
                    }
                };
            });
        }).catch(err => swal.error(err.response?.data?.message || err.message)).finally(() => setLoading(false))
    }
    return (
        <>
            <button disabled={familyDetails.wards.length === 0} onClick={toggleModal} className='btn btn-sm btn-soft-success cursor-pointer'>Ward Details</button>
            <Modal className="fade" size="md" centered backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Ward Details : {familyDetails.father_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (<DataLoading />) : (
                        <Row>
                            {familyDetails.wards.map((ward, index) => (
                                <Col xs={12} key={index}>
                                    <Card className="shadow-lg border-0">
                                        <Card.Body className="bg-soft-warning rounded-3 d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title>{ward.first_name} {ward.last_name}</Card.Title>
                                                {ward.courses.find(course => course.pivot.status === "active")?.name}
                                            </div>
                                            <button disabled={loading === ward.id} onClick={() => handleRemoveWard(ward.id, familyDetails.id)} className="btn btn-sm btn-danger">
                                                <i className="bx bx-trash align-middle"></i>
                                            </button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>)}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WardDetailsModal