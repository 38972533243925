import React, { useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { homework } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { FetchQuestion } from './FetchQuestion';
import dayjs from 'dayjs';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { HomeWorkList } from './HomeWorkList';
export const HomeWork = ({ class_id }) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.auth._user);
    const [fetchQuestion, setAllQuestion] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        // const formattedDate = dayjs(formData.get('submission_date')).format('YYYY-MM-DD-HH:mm:ss');
        const homeworkData = {
            title: formData.get('title'),
            description: formData.get('description'),
            submission_date: formData.get('submission_date'),
            total_marks: formData.get('total_marks'),
            created_by: userData.id,
            creator_name: `${userData.first_name} ${userData.last_name}`,
            for_class_id: class_id,
            question_data: fetchQuestion,
        };
        dispatch(setPreloader({ loader: true, message: "Please wait ..." }));
        homework.add(homeworkData)
        .then(res => {swal.success("success",res.message);})
        .catch(e => swal.error('Error', e.response ? e.response.data.message : e.message))
        .finally(() => dispatch(setPreloader({ loader: false, message: "" })));
    };
    const removeSelectedQuestion = (question) => setAllQuestion(state=>state.filter(s=>s.id !== question.id));
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="">{cell.row.index + 1}</p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        {Header: "Question",Cell: cell =><div style={{minWidth:'300px'}}>{cell.row.original.question}</div>},
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => (<Button onClick={e=>removeSelectedQuestion(cell.row.original)} className="btn btn-sm btn-soft-danger me-1"><i className="ri-delete-bin-fill" /></Button>)
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                var srn=1;
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" >
                            <h5 className="fs-13 mb-1">
                                {/* <a href="#" className="link text-dark">{srn+=1}.</a> */}
                                <a href="#">{row.question}</a>
                            </h5>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <Button onClick={() => removeSelectedQuestion(row)} className="btn btn-sm btn-soft-danger me-1">
                                    <i className="ri-delete-bin-fill" />
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        
    ], [fetchQuestion]);
    return (
        <Row>
            <Col xs={12}>
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button className="nav-link btn btn-soft-success active" id="nav-createhomework-tab" data-bs-toggle="tab" data-bs-target="#nav-createhomework" type="button" role="tab" aria-controls="nav-createhomework" aria-selected="true">
                            Create Homework
                        </button>
                        <button className="nav-link btn btn-soft-success" id="nav-listhomework-tab" data-bs-toggle="tab" data-bs-target="#nav-listhomework" type="button" role="tab" aria-controls="nav-listhomework" aria-selected="false">
                            Homework List
                        </button>
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-createhomework" role="tabpanel" aria-labelledby="nav-createhomework-tab">
                        <form onSubmit={handleSubmit}>
                            <input type="hidden" name="for_class_id" value={class_id} />
                            <div className='row mt-3'>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="mb-3">
                                        <label htmlFor="title" className="form-label">Title</label>
                                        <input type="text" className="form-control" id="title" name="title" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">Description</label>
                                        <textarea className="form-control" id="description" name="description"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="submission_date" className="form-label">Submission Date</label>
                                        <input type="date" className="form-control" id="submission_date" name="submission_date" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="image" className="form-label">Upload image</label>
                                        <input type="file" className="form-control" id="image" name="image" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <FetchQuestion setAllQuestion={setAllQuestion} />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-sm-3">
                                    <div>
                                        <TableResponsive showFilter={false} columns={columns}data={fetchQuestion} showCustomOptionPage={false} noRecord="No Record Course List"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="tab-pane fade" id="nav-listhomework" role="tabpanel" aria-labelledby="nav-listhomework-tab">
                        <HomeWorkList classId={class_id} activeTab={true} />
                    </div>
                </div>
            </Col>
        </Row>
    );
};
