import React, { useEffect, useState, useMemo } from 'react';
import BreadCrumb from "../../../components/common/BreadCrumb";
import { TableResponsive } from '../../../components/common/TableResponsive';
import { Row, Col, Button } from "react-bootstrap";
import { ASSET_URL, course, homework } from '../../../helper/api_url';
import { formatDate } from '../../../helper/formatDate';
import { Link } from 'react-router-dom';
import CustomSelect from '../../../components/CustomSelect';
import { swal } from '../../../helper/swal';
import { CreateHomeWork } from "./CreateHomeWork";
import Swal from 'sweetalert2';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useDispatch } from 'react-redux';
import ImageViewer from '../../../components/ImageViewer';
import copyImage from '../../../assets/images/schoolCopy/copypage.png'
function HomeWorkClassWise() {
    const dispatch = useDispatch();
    const [courseOption, setCourseOption] = useState([]);
    const [selectedClass, setSelectedClass] = useState(null);
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        course.list()
            .then(res => setCourseOption(res.data.course?.map(item => ({ value: item.id, label: item.name }))))
            .catch(err => swal.error(err.response?.data?.message || err.message));
    }, []);
    useEffect(() => {
        if (selectedClass) {
            const formData = new FormData();
            formData.append('class_id', selectedClass);
            dispatch(setPreloader({ loader: true, message: 'Fetching homework...' }));
            homework.list(formData)
                .then(res => setTableData(res.data.homework))
                .catch(err => swal.error(err.response?.data?.message || err.message))
                .finally(() => dispatch(setPreloader({ loader: false, message: '' })));
        } else {
            setTableData([]);
        }
    }, [selectedClass]);
    const handleHomeworkDelete = (homeId, course_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this homework?",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: "Delete",
            denyButtonText: `No`
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setPreloader({ loader: true, message: 'Deleting, please wait...' }));
                homework.delete(homeId, course_id)
                    .then(res => {
                        setTableData(tableData.filter(td => td.id !== homeId)); // Update table data
                        swal.success(res.data.message);
                    })
                    .catch(err => swal.error(err.response?.data?.message || err.message))
                    .finally(() => dispatch(setPreloader({ loader: false, message: "" })));
            }
        });
    };

    const columns = useMemo(() => [
        {
            Header: "SrNo",
            accessor: "id",
            Cell: cell => <p>{cell.row.index + 1}</p>,
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Subject",
            accessor: "questions[0].subject",
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Question",
            Cell: ({ row }) => {
                const questions = row.original.questions;
                return (
                    <>
                        {questions.map((item, idx) => (
                            <>
                                <ImageViewer image={ASSET_URL+item.image==null?copyImage:ASSET_URL + item.image}>
                                    <span> <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={ASSET_URL+item.image==null?copyImage:ASSET_URL + item.image} alt="User Avatar" />{item.question} </span>
                                </ImageViewer>
                              
                            </>
                        ))}
                    </>
                );
            },
            HeaderClass: '',
            DataClass: '',
        },
        {
            Header: "Title",
            accessor: "title",
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Description",
            accessor: "description",
            HeaderClass: 'text-center',
            DataClass: 'text-center'
        },
        {
            Header: "Submission Date",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => (
                <span>{formatDate(cell.row.original.submission_date)}</span>
            )
        },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return (
                    <Button
                        className="btn btn-sm btn-soft-danger me-1"
                        onClick={() => handleHomeworkDelete(row.id, row.course_id)}
                    >
                        <i className="ri-delete-bin-fill" />
                    </Button>
                );
            },
        }
    ], [tableData]);

    return (
        <>
            <BreadCrumb title="Manage" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="mb-0">HomeWork Plan</h3>
                        {selectedClass && (
                            <>
                                <Link to={"/question"} className='btn btn-soft-info add-btn waves-effect'>
                                    <i className="ri-add-line align-bottom me-1"></i>
                                    <span>Create Question</span>
                                </Link>
                                <CreateHomeWork class_id={selectedClass} />
                            </>
                        )}
                        <CustomSelect
                            placeholder="Select a class..."
                            isSearchable={true}
                            options={courseOption}
                            onChange={(selected) => setSelectedClass(selected?.value)} // Update selected class
                        />
                    </div>
                    <Row className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
                        <Col className="text-center">
                            {selectedClass ? (
                                tableData.length > 0 ? (
                                    <TableResponsive columns={columns} data={tableData} />
                                ) : (
                                    <>
                                        <lord-icon
                                            src="https://cdn.lordicon.com/nqtddedc.json"
                                            trigger="hover"
                                            colors="primary:#e83a30"
                                            style={{ width: '250px', height: '250px' }}>
                                        </lord-icon>
                                        <p>No Homework Plan Found</p>
                                    </>
                                )
                            ) : (
                                <>
                                    <p>Please select a class to view Homework plans</p>
                                    <lord-icon
                                        src='https://cdn.lordicon.com/evxithfv.json'
                                        trigger="loop"
                                        colors="primary:#0ab39c"
                                        className=""
                                        style={{ width: "100px", height: "100px" }}
                                    ></lord-icon>
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default HomeWorkClassWise;
