import React from 'react'
import { useNavigate } from 'react-router-dom';

export const FeeButton = (props) => {
    const {id}=props;
    const navigate=useNavigate();
    const handleClick=(sid)=>{
        navigate(`/student/${sid}/fee`)
    }
    return (
        <>
            <button onClick={()=>handleClick(id)} className='btn btn-sm btn-soft-dark add-btn waves-effect mx-1'>
                {props.children || (<>
                    <i className="ri-coins-line align-bottom me-1"></i>
                    <span>Fee Dues</span>
                </> )}
            </button>
        </>
    )
}
