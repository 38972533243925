import React, { useEffect, useMemo, useState } from 'react';
import { Card, Modal, Row, Col, Button } from 'react-bootstrap';
import { useModalHandler } from '../../../helper/custom_hook';
import { swal } from '../../../helper/swal';
import { ASSET_URL, examination } from '../../../helper/api_url';
import CustomSelect from '../../../components/CustomSelect';
import { TableResponsive } from '../../../components/common/TableResponsive';
import ImageViewer from '../../../components/ImageViewer';
import copyImage from '../../../assets/images/schoolCopy/copypage.png'
export function FetchQuestion({ setAllQuestion}) {
    const { toggleModal, status } = useModalHandler();
    const [tableData, setTableData] = useState([]);
    const [showQuestion, setShowQuestion] = useState([]);
    const [selectedQuestions, setSelectedQuestions] = useState([]);

    useEffect(() => {
        examination.readQuestions()
            .then(res => setTableData(res.data.question))
            .catch(err => swal.error(err.response?.data?.message || err.message));
    }, [setAllQuestion]);

    const handleQuestionChange = e => {
        const question = tableData.filter(item => item.subject === e.value);
        setShowQuestion(question);
    };

    const handleSelectQuestion = (questionId) => {
        setSelectedQuestions(prevSelected => {
            if (prevSelected.includes(questionId)) {
                return prevSelected.filter(id => id !== questionId);
            } else {
                return [...prevSelected, questionId];
            }
        });
    };

    const handleConfirmQuestions = () => {
        const selectedQuestionData = showQuestion.filter(q => selectedQuestions.includes(q.id));
        setAllQuestion(state => [...state, ...selectedQuestionData.map(q => ({ ...q, marks: 0 }))]);
        toggleModal();
    };

    const columns = useMemo(() => [
        {
            Header: "Choice Question",
            accessor: "id",
            Cell: cell => (
                <>
                <input
                    type="checkbox"
                    checked={selectedQuestions.includes(cell.row.original.id)}
                    onChange={() => handleSelectQuestion(cell.row.original.id)}
                    className='me-2'
                    id='question'
                />
               <label htmlFor="question"><span>{cell.row.original.question}</span></label> 
                </>
            ),
            HeaderClass: 'text-center',
            // DataClass: 'text-center'
        },
        {
            Header: "Image",
            accessor: "question",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl = cell.row.original.image===null?copyImage:ASSET_URL + cell.row.original.image;
                return (
                    <ImageViewer image={imageUrl}>
                        <span> <img className="me-2  " style={{objectFit:'cover',height:'50px',width:'50px'}} src={imageUrl} alt="User Avatar" /></span>
                    </ImageViewer>
            )
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                const bs_colors = ['primary', 'info', 'warning', 'danger', 'success', 'light', 'secondary'];
                const badge_class = row.id <= 6 ? `badge-outline-${bs_colors[row.id]}` : `badge-S-${bs_colors[row.id % 6]}`;
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" data-id="1">
                            <h5 className="fs-13 mb-1">
                                <input type="checkbox" checked={selectedQuestions.includes(row.id)} id={`quest_${row.id}`} onChange={() => handleSelectQuestion(row.id)} />
                            </h5>
                            <label htmlFor={`quest_${row.id}`}><p className="text-muted mb-0">{row.question} </p></label>
                            
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                {/* <Button className="btn btn-sm btn-soft-danger me-1">
                                    <i className="ri-delete-bin-fill" />
                                </Button> */}
                            </div>
                        </div>
                    </div>
                )
            }
        }
    ], [selectedQuestions]);

    return (
        <>
            <button onClick={(e) => { e.preventDefault(); toggleModal() }} className='btn btn-soft-info add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>select Question</span>
            </button>

            <Modal centered backdrop="static" show={status} onHide={toggleModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Choose Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mx-3'>
                            <Row className='mb-3'>
                                <Col xs={6} className='mx-auto'>
                                    <CustomSelect
                                        id="subject"
                                        options={tableData.map(item => ({ label: item.subject, value: item.subject }))}
                                        onChange={handleQuestionChange}
                                        placeholder="Select Subject..."
                                    />
                                </Col>
                            </Row>
                            <TableResponsive
                                columns={columns}
                                data={showQuestion}
                                isShowingPageLength={false}
                                showFilter={false}
                                isPagination={false}
                                showCustomOptionPage={false}
                                noRecord="No Record List"
                            />
                            <div className='text-center mt-3'>
                                <Button className="btn btn-sm btn-soft-primary" onClick={handleConfirmQuestions}>
                                    Confirm Questions
                                </Button>
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
