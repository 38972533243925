import LZString from 'lz-string';

const viewAdmitCard = (url,params={}) => {
    const token = LZString.compressToEncodedURIComponent(localStorage.getItem("_token"));
    const schoolCode = encodeURIComponent(window.__school_code);
    url = `${url}?&s=${schoolCode}&t=${token}`;
    Object.keys(params).forEach(key => {
        if(params[key]) url += `&${key}=${params[key]}`;
    });
    window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=800,height=600');
}

export const handleAdmitCardViewByClass = (classId) => {
    let url = `https://management.unboxschool.in/printer/admit-card/by-class.php`;
    viewAdmitCard(url,{c:classId});
}

export const handleAdmitCardViewByStudent = (studentId) => {
    let url = `https://management.unboxschool.in/printer/admit-card/by-student.php`;
    viewAdmitCard(url,{st:studentId});
}

