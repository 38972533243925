import { Badge, Card, CardBody, CardHeader, CardTitle } from "react-bootstrap";
import { TableResponsive } from "../../../components/common/TableResponsive";
import { family } from "../../../helper/api_url";
import { useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { swal } from "../../../helper/swal";
import ChangeFamilyModal from "./change-family-modal";
import Swal from "sweetalert2";
import NewFamilyModel from "./new-family-model";
import { useDispatch } from "react-redux";
import { setPreloader } from "../../../features/Ui/uiSlice";
import WardDetailsModal from "./ward-details-modal";
import UpdateFamilyModel from "./UpdateFamilyModel";

const Family = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { data: families = [], isLoading, isError, error } = useQuery({
        queryKey: ['families'],
        queryFn: () => family.getAll(),
        staleTime: 20 * 60 * 1000,
        gcTime: 20 * 60 * 1000,
        select: (data) => data.data.family
    });
    useEffect(() => {
        if (isError) swal.error(error.response ? error.response.data.message : error.message)
    }, [isError, error])

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setPreloader({ loader: true, message: 'please wait ... ' }));
                family.delete(id).then(() => {
                    queryClient.setQueryData(['families'], (oldData) => {
                        if (!oldData) return oldData;
                        return {
                            ...oldData,
                            data: {
                                ...oldData.data,
                                family: oldData.data.family.filter(f => f.id !== id)
                            }
                        };
                    });
                }).catch((err) => {
                    swal.error(err.response ? err.response.data.message : err.message)
                }).finally(() => {
                    dispatch(setPreloader({ loader: false, message: "" }));
                })
            }
        })
    }
    const familyColumns = useMemo(() => [
        {
            Header: "S.No",
            Cell: (cell) => cell.row.index + 1
        },
        {
            Header: "Father's Name",
            accessor: "father_name"
        },
        {
            Header: "Mother's Name",
            accessor: "mother_name"
        },
        {
            Header: "Phone",
            accessor: "father_mobile"
        },
        {
            Header: "wards",
            HeaderClass: "text-center",
            DataClass: "text-center",
            Cell: (cell) => {
                return <Badge bg="soft-danger">{cell.row.original.wards.length}</Badge>
            }
        },
        {
            Header: "Action",
            HeaderClass: "text-center",
            DataClass: "d-flex justify-content-center",
            Cell: (cell) => {
                return (
                    <div className="d-flex gap-3 bg-light p-2 rounded" style={{
                        width: 'fit-content'
                    }}>
                        <WardDetailsModal familyDetails={cell.row.original} />
                        <UpdateFamilyModel data={cell.row.original}  />
                        <button onClick={() => handleDelete(cell.row.original.id)} className='btn btn-sm btn-soft-danger cursor-pointer'><i className="ri-delete-bin-line"></i></button>
                    </div>
                )
            }
        },
        {
            Header: "List",
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="fs-13 mb-1">
                                <span className="text-dark">Family ID: #{row.id}</span>
                            </h5>
                            <p className="text-muted mb-0">
                                <span className="me-2 d-block d-md-inline">Father: {row.father_name}</span>
                                <span className="d-block d-md-inline">Mother: {row.mother_name}</span>
                                <p>Phone : {row.father_mobile}</p>
                            </p>
                            <span className="badge bg-soft-danger">
                                Wards: {row.wards.length}
                            </span>
                        </div>
                        <div className="flex-shrink-0 p-2 bg-light rounded">
                            <div className="d-grid gap-2">
                                <WardDetailsModal familyDetails={row} />
                                <button onClick={() => handleDelete(row.id)} className='btn btn-sm btn-soft-danger cursor-pointer'><i className="ri-delete-bin-line"></i></button>
                            </div>
                        </div>
                    </div>
                )
            }
        }

    ], [families])
    return (
        <div>
            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <CardTitle>Family List</CardTitle>
                    <div className="d-flex justify-between gap-4">
                        <NewFamilyModel className="btn btn-soft-primary" />
                        <ChangeFamilyModal className="btn btn-soft-warning" />
                    </div>
                </CardHeader>
                <CardBody>
                    <TableResponsive columns={familyColumns} data={families} isLoading={isLoading} />
                </CardBody>
            </Card>
        </div>
    );
}

export default Family;