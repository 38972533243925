import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Card, Button } from "react-bootstrap";
import PieChart from '../../../helper/Charts/PieChart';
import DoughnutChart from '../../../helper/Charts/DoughnutChart';
import { primary, info, success, warning, gray_900, danger } from '../../../helper/Colors';
import { ASSET_URL, dashboard } from '../../../helper/api_url';
import CustomSelect from '../../../components/CustomSelect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TableResponsive } from '../../../components/common/TableResponsive';
import ViewInvoiceButton from '../Accounts/ViewInvoiceButton';
import { handleViewInvoice } from '../../../helper/view-invoice';
import { Bar, Doughnut, Line, PolarArea } from 'react-chartjs-2';
// import { CDBContainer } from 'cdbreact';

function Index() {
    const AuthUser = useSelector(state => state.auth);
    const [pieChartData, setPichartData] = useState({ labels: [], datasets: [] })
    const [dashboardData, setDashboardData] = useState([]);
    const [recentTransactions, setRecentTransactions] = useState([]);
    const [duration, setDuration] = useState('daily');
    const [datas] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Present',
                backgroundColor: 'rgba(194, 116, 161, 0.5)',
                borderColor: 'rgb(194, 116, 161)',
                data: [65, 59, 90, 81, 56, 55, 40, 65, 59, 90, 81, 56, 55, 40],
            },
            {
                label: 'Absent',
                backgroundColor: 'rgba(71, 225, 167, 0.5)',
                borderColor: 'rgb(71, 225, 167)',
                data: [28, 48, 40, 19, 96, 27, 10, 28, 48, 40, 19, 96, 27, 10],
            },
            {
                label: 'Leave',
                backgroundColor: 'rgba(232, 155, 40, 0.5)',
                borderColor: 'rgb(232, 132, 61)',
                data: [8, 4, 1, 0, 3, 27, 10, 28, 8, 0, 9, 6, 7, 0],
            },
            {
                label: 'Unmarked',
                backgroundColor: 'rgba(22, 22, 22, 0.5)',
                borderColor: 'rgb(57, 57, 57)',
                data: [10, 3, 1, 0, 3, 7, 0, 2, 8, 0, 9, 6, 7, 0],
            },
        ],
    });
    // const labels = Utils.months({ count: 7 });
    const mydata = {
        labels: ['Jan', 'Feb', 'Mar', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
            label: 'Total Dues',
            data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56],
            fill: false,
            borderColor: 'rgb(167, 30, 30)',
            tension: 0.1
        }
        ,
            {
            label: 'Collection',
            data: [50, 19, 80, 31, 56, 25, 40, 5, 59, 0, 81, 56],
            fill: false,
            tension: 0.1,
            borderColor: 'rgb(27, 108, 174)',
        },
            {
            label: 'Expense',
            data: [15, 20, 80, 10, 56, 15, 40, 50, 59, 9, 81, 56],
            fill: false,
            tension: 0.1,
            borderColor: 'rgb(187, 129, 35)',
        },
            {
            label: 'Profit',
            data: [15, 10, 80, 10, 56, 15, 40, 50, 59, 9, 81, 56],
            fill: false,
            tension: 0.1,
            borderColor: 'rgb(27, 53, 9)',
        },
    ]
    };
    const Recentdata = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Looping tension',
          data: [65, 59, 80, 81, 26, 55, 40],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
        }]
      };

      const DailyAttendancedata = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
          {
            label: "Employee",
            data: [33, 53, 85, 41, 44, 65],
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)"
          },
          {
            label: "Student",
            data: [33, 25, 35, 51, 54, 76],
            fill: false,
            borderColor: "#742774"
          }
        ]
      };
      const OverViewdata = {
        labels: [
          'Addmission',
          'Enquiry',
          'Syllabus',
          'Total Exams'
        ],
        datasets: [{
          label: 'overView',
          data: [11, 16, 7, 3],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(75, 192, 192)',
            'rgb(255, 205, 86)',
            'rgb(201, 203, 207)',
            'rgb(54, 162, 235)'
          ]
        }]
      };
    useEffect(() => {
        dashboard.data().then(res => {
            setDashboardData(res.data)
            setRecentTransactions(res.data?.feesData?.recent_transactions);
        }).catch(e => console.log(e));
    }, []);
    const columns = useMemo(() => [
        {
            Header: "Name",
            Cell: (cell) => {
                const imageUrl = ASSET_URL + cell.row.original.payer?.avatar;
                return (<span>
                    <img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />
                    {cell.row.original.payer_name}
                </span>)
            },
        },
        {
            Header: "Details",
            Cell: cell => {
                const row = cell.row.original;
                return <div className='d-flex justify-content-between'>
                    <span>{row?.total_amount} ({row?.payment_mode})</span>
                    <Button onClick={(e) => handleViewInvoice(row.id)} className="btn btn-sm btn-soft-info me-1">
                        <i className="ri-eye-fill" />
                    </Button>
                </div>
            }
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: row => {
                const imageUrl = ASSET_URL + row.payer?.avatar;
                return <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center gap-4'>
                        <span><img className="me-2 rounded-circle header-profile-user" style={{ objectFit: 'cover' }} src={imageUrl} alt="User Avatar" />
                            {row.payer_name}</span>
                        <span className='text-muted'>{row.total_amount} ({row.payment_mode})</span>
                    </div>
                    <Button onClick={(e) => handleViewInvoice(row.id)} className="btn btn-sm btn-soft-info me-1">
                        <i className="ri-eye-fill" />
                    </Button>
                </div>
            }
        }

    ], [])
    useEffect(() => {
        setPichartData({
            labels: dashboardData?.attendanceData
                ? Object.keys(dashboardData?.attendanceData)?.filter(i => i !== 'no_of_students')?.map(d => d.split('_')[2].toUpperCase())
                : [],
            datasets: [{
                label: 'No of Students',
                data: dashboardData.attendanceData
                    ? Object.keys(dashboardData?.attendanceData)?.filter(i => i !== 'no_of_students')?.map(d => dashboardData?.attendanceData[d])
                    : [],
                backgroundColor: [success, danger, warning, primary],
            }]
        })
    }, [dashboardData])
    const data = [
        { year: 2010, count: 10 },
        { year: 2011, count: 20 },
        { year: 2012, count: 15 },
        { year: 2013, count: 25 },
        { year: 2014, count: 22 },
        { year: 2015, count: 30 },
        { year: 2016, count: 28 },
    ];
    const [chartData, setChartData] = useState({
        labels: data.map(d => d.year),
        datasets: [{
            label: 'Count',
            data: data.map(d => d.count),
            backgroundColor: [primary, info, success, warning],
            borderColor: primary,
            borderWidth: 2,
        }]
    });

    const getFees = () => {
        return duration == 'daily'
            ? dashboardData?.feesData?.fees_payment_today
            : (duration == 'monthly' ? dashboardData?.feesData?.fees_payment_month : dashboardData?.feesData?.fees_payment_year)
    }
    const getExpense = () => {
        return duration == 'daily'
            ? dashboardData?.feesData?.expense_today
            : (duration == 'monthly' ? dashboardData?.feesData?.expense_month : dashboardData?.feesData?.expense_year)
    }


    const DashboardCard = ({ icon, count, label, color }) => {
        return (
            <Card className="text-white shadow" style={{ backgroundColor: color }}>
                <Card.Body className="d-flex align-items-center justify-content-between">
                    <div className="icon-container">
                        <i className={`${icon} fs-1`} />
                    </div>
                    <div>
                        <h2 className="mb-1">{count}</h2>
                        <p className="mb-0">{label}</p>
                    </div>
                </Card.Body>
            </Card>
        );
    };
    return (
        <>
         {/* <Row className="g-4">
            <Col md={3}>
                <DashboardCard
                    icon="ri-book-fill"
                    count="3256"
                    label="Students"
                    color="#6f42c1"
                />
            </Col>
            <Col md={3}>
                <DashboardCard
                    icon="ri-user-fill"
                    count="8"
                    label="Employees"
                    color="#fd7e14"
                />
            </Col>
            <Col md={3}>
                <DashboardCard
                    icon="ri-bookmark-fill"
                    count="16"
                    label="Courses"
                    color="#20c997"
                />
            </Col>
            <Col md={3}>
                <DashboardCard
                    icon="ri-wallet-3-fill"
                    count="4"
                    label="Batch"
                    color="#0d6efd"
                />
            </Col>
        </Row> */}
        
            {AuthUser?._user?.role?.priority >= 80 && (
                <Row className='overflow-auto d-flex custom-scrollbar mb-4'>
                    <Col xs={12} className='d-flex py-1 mb-2 justify-content-between align-items-center'>
                        <h3>Fees Information</ h3>
                        <div className="" style={{ width: '200px' }}>
                            <CustomSelect defaultValue={{ label: 'Daily', value: 'daily' }} onChange={e => setDuration(e.value)} options={[
                                { label: 'Daily', value: 'daily' },
                                { label: 'Monthly', value: 'monthly' },
                                { label: 'Yearly', value: 'yearly' },
                            ]} />
                        </div>
                    </Col>
                    <Col className='mb-4'>
                        <Link to="/dues-list">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '150px' }}>
                                <h5 className="text-muted text-uppercase fs-13"> Total Dues</h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-danger rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-bank-fill display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0">
                                            <span className="counter-value">
                                                {dashboardData?.feesData?.dues}
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='mb-4'>
                        <Link to="/dues-list">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '150px' }}>
                                <h5 className="text-muted text-uppercase fs-13"> Collection</h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-info rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-wallet-3-fill display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0">
                                            <span className="counter-value" >
                                                {getFees()}
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='mb-4'>
                        <Link to="/dues-list">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '150px' }}>
                                <h5 className="text-muted text-uppercase fs-13"> Expense</h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-warning rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-refund-2-line display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0">
                                            <span className="counter-value">
                                                {getExpense()}
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='mb-4'>
                        <Link to="/dues-list">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '150px' }}>
                                <h5 className="text-muted text-uppercase fs-13"> Profit</h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-success rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-coins-fill display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0">
                                            <span className="counter-value" >
                                                {parseFloat(getFees() || 0) - parseFloat(getExpense() || 0)}
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            )}

            {/* <Animation data={Recentdata} /> */}
            <Row className=' mb-4'>
                <Col lg={6} sm={6} className='mb-4' >
                        {/* <h3 className="mt-5">Attendance Information</h3> */}
                   <Card className='p-3'>
                        <Card.Header className='m-0'>Attendance</Card.Header>
                        <Bar data={datas} options={{ responsive: true }} />
                    </Card>
                   
                </Col>
                <Col lg={6} sm={6} className='mb-4' style={{ minWidth: '500px', height: '450px' }}>
                <Card className='p-3'>
                    <Card.Header className='m-0'>Daliy Attendence Overview</Card.Header>
                {/* <Doughnut data={mydata} /> */}
                <Line data={DailyAttendancedata} />
                </Card>
                </Col>
                <Col lg={6} sm={6} className='mb-4' style={{ minWidth: '500px', height: '450px' }}>
                <Card className='p-3'>
                    <Card.Header className='m-0'>Total Profit</Card.Header>
                    <div className="d-flex justify-content-center"style={{ height: '400px' }}>
                <Line data={mydata} />
                </div>
                </Card>
                </Col>
                <Col lg={6} sm={6} className='mb-4' >
                <Card className='p-3' >
                    <Card.Header className='m-0'>OverView</Card.Header>
                    <div className="d-flex justify-content-center"style={{ height: '400px' }}>

                <PolarArea data={OverViewdata} />
                    </div>
                </Card>
                </Col>
            </Row>
            {AuthUser?._user?.role?.priority >= 60 && (
                <Row className="overflow-auto d-flex custom-scrollbar mb-4">
                    <Col xs={12} className='d-flex py-1 mb-2 justify-content-between align-items-center'>
                        <h3>Attendance Information</ h3>
                        <div className="" style={{ width: '200px' }}>
                            <CustomSelect defaultValue={{ label: 'Today', value: 'today' }} options={[
                                { label: 'Today', value: 'today' },
                                { label: 'Yesterday', value: 'yesterday' },
                                { label: '2 days ago', value: '2dayago' },
                            ]} />
                        </div>
                    </Col>
                    <Col className='mb-2'>
                        <Link to="/attendance">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '200px' }}>
                                <h5 className="text-muted text-uppercase fs-13"> Total Students <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-info rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-team-line display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.userData?.no_of_students}>{dashboardData?.userData?.no_of_students}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='mb-2'>
                        <Link to="/attendance">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '200px' }}>
                                <h5 className="text-muted text-uppercase fs-13"> Present Students <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-success rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className=" ri-user-follow-fill display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_present_students}>{dashboardData?.attendanceData?.no_of_present_students}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='mb-2'>
                        <Link to="/attendance">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '200px' }}>
                                <h5 className="text-muted text-uppercase fs-13">Absent Students <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-danger rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-user-4-fill display-6 text-white"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_absent_students}>{dashboardData?.attendanceData?.no_of_absent_students}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='mb-2'>
                        <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '200px' }}>
                            <h5 className="text-muted text-uppercase fs-13">Students On Leave<i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                            <div className="d-flex align-items-center">
                                <div className="bg-warning rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                    <i className="ri-information-fill display-6 text-white"></i>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_leave_students}>{dashboardData?.attendanceData?.no_of_leave_students}</span></h2>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='mb-2'>
                        <Link to="/attendance">
                            <div className="py-4 px-3 border border-dashed rounded" style={{ minWidth: '200px' }}>
                                <h5 className="text-muted text-uppercase fs-13">Unmarked Attendance<i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                <div className="d-flex align-items-center">
                                    <div className="bg-dark rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{ width: '60px', height: '60px' }}>
                                        <i className="ri-question-fill display-6 text-light"></i>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_unmarked_students}>{dashboardData?.attendanceData?.no_of_unmarked_students}</span></h2>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            )}
            {AuthUser?._user?.role?.priority >= 80 && (
                <Row className='flex'>
                    <Col style={{ minWidth: '300px' }}>
                        <PieChart chartData={pieChartData} />
                    </Col>
                    <Col style={{ minWidth: '300px' }}>
                        <DoughnutChart chartData={chartData} />
                    </Col>
                    <Col style={{ minWidth: '300px' }}>
                        {/* <BarChart chartData={chartData} /> */}
                        <Card style={{ height: 'calc(100% - 1.5rem)' }}>
                            <Card.Header className='m-0'>
                                <Card.Title>
                                    <h5>Recent Transactions</h5>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='px-2 pt-2 pb-0'>
                                <TableResponsive
                                    showFilter={false}
                                    isPagination={false}
                                    isShowingPageLength={false}
                                    showCustomOptionPage={false}
                                    columns={columns}
                                    data={recentTransactions}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </>

    )
}

export default Index