import React, { useMemo, useState } from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb'
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import { usePageInitialtor } from '../../../helper/custom_hook'
import { ASSET_URL, student } from '../../../helper/api_url'
import { Switch } from 'antd'
import { ViewUserModal } from '../AllUser/ViewUserModal'
import { UpdateAllUserModal } from '../AllUser/UpdateAllUserModal'
import { Link, useNavigate } from 'react-router-dom'
import { formatDate } from '../../../helper/formatDate'
import { EnrollementCourse } from './EnrollementCourse'
import { FeeButton } from '../Accounts/FeeButton'

export const AddmissionList = () => {
    const {tableData,setTableData,apiHandler} = usePageInitialtor(student);
    const {handleDelete} = apiHandler;
    const navigate = useNavigate();
    const handleUpdateAdmission = (id) => {
        navigate('/admission/update/'+id);
    }
    const columns = useMemo(()=>[
        {
            Header: "Name",
            accessor: "first_name",
            HeaderClass:'',
            DataClass:'',
            Cell:(cell)=> {
                const imageUrl =ASSET_URL + cell.row.original.avatar;
                return (<span> <img className="me-2 rounded-circle header-profile-user" style={{objectFit:'cover'}} src={imageUrl} alt="User Avatar" />{cell.row.original.first_name} {cell.row.original.last_name}</span>)
            }
        },
        {
            Header: "Phone",
            accessor: "phone",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Email",
            accessor: "email",
            HeaderClass:'',
            DataClass:'',
        },
        {
            Header: "Admission Date",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell:(cell)=>{
                const row=cell.row.original;
                return(<span>{formatDate(row.created_at)}</span>)}
        },
        {
            Header: "Action",
            HeaderClass:'text-center',
            DataClass:'text-center',
            Cell: (cell) => {
                const row= cell.row.original;
                return ( 
                    <div className="">
                    <FeeButton id={row.id} />
                    <ViewUserModal data={row}  />
                    <Button className='btn btn-sm btn-soft-success me-1' onClick={e=>handleUpdateAdmission(row.id)} ><i className="ri-pencil-fill" /></Button>
                        <Button onClick={()=>handleDelete({name:row.first_name,id:row.id})} className="btn btn-sm btn-soft-danger me-1" >
                            <i className="ri-delete-bin-fill" />  
                        </Button>
                    </div>
              )
            },
          },
          {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl = ASSET_URL+row.avatar;
                return (
                <div className="d-flex">
                     <ViewUserModal data={row}> 
                   <img className="me-2 rounded-circle header-profile-user" src={imageUrl} alt="User Avatar" />
                   </ViewUserModal>
                    <div className="flex-grow-1" data-id="1">
                        <h5 className="fs-13 mb-1">
                            <a href="#" className="link text-dark"></a>
                            <a href="#">{row.first_name} {row.last_name}</a>
                        </h5>
                        <p className="text-muted mb-0"><a href={`tel:${row.phone}`}><i className='bx bx-phone text-success' />{row.phone}</a> | <i className='bx bx-mail-send' />{row.email}</p>
                    </div>
                    <div className="flex-shrink-0">
                        <div>
                            <Switch 
                                className={row.is_active ?'bg-primary me-2':'me-2'}
                                loading={row.changing?true:false}
                                checked={row.is_active} 
                                checkedChildren={(<span style={{fontSize:"10px"}}>Active</span>)} 
                                unCheckedChildren={(<span style={{fontSize:"10px"}}>Deactive</span>)} 
                            />
                            <UpdateAllUserModal data={row} userType='staff' apiHandler={apiHandler}/>
                            <button onClick={()=>handleDelete({name:row.first_name,id:row.id})} className="btn btn-sm btn-soft-danger me-1" data-id="1"> <i className="ri-delete-bin-fill"></i> </button>

                        </div>
                    </div>
                </div>
                )
            }
          }
    ],[]);
  return (
    <>
    <BreadCrumb title="Student's" prevPage="Home" prevPath="/dashboard/Admission"   />
    <Row>
        <Col xs={12}>
            <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="card-title mb-0">Admission List</h5>
                    <Link to="/admission/new" className='btn btn-soft-success add-btn waves-effect'><i className="ri-add-line align-bottom me-1"></i><span>Addmission</span></Link>
                </CardHeader>
                <CardBody className="">   
                        <TableResponsive columns={columns} data={tableData} noRecord="No Record in Staff List"  />
                </CardBody>
            </Card>
        </Col>
    </Row>
    </>
  )
}
