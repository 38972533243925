import React, { useMemo, useState } from 'react'
import { useModalHandler, useWebCam } from '../../../helper/custom_hook';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FetchQuestion } from './FetchQuestion';
import { useDispatch, useSelector } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { swal } from '../../../helper/swal';
import { examination, homework } from '../../../helper/api_url';
import { TableResponsive } from '../../../components/common/TableResponsive';
import dummyUser from "../../../assets/images/users/uploadfile.png"
import Camera from '../../../components/common/Camera';
export const CreateHomeWork = ({ class_id }) => {
    const { toggleModal, status, userProfile, setUserProfile } = useModalHandler();
    const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();
    const userData = useSelector(state => state.auth._user);
    const [fetchQuestion, setAllQuestion] = useState([]);
    const [tableData, setTableData] = useState([]);
    const toggleVisibility = () => { setIsVisible(!isVisible); };
    const onSave = f => { setCapturedImageToInput(f, document.getElementById('profile-img-file-input')) }
   
    const handleAddQuestion = () => {
        
        const formData = new FormData();  
        formData.append('class_id', class_id);
        formData.append('subject', document.getElementById('course_subject').value);
        formData.append('question', document.getElementById('course_question').value);
        formData.append('image', document.getElementById('profile-img-file-input').files[0]);
        dispatch(setPreloader({ loader: true, message: 'adding Question please wait ..' }))
        examination.createQuestion(formData)
            .then(res => (setAllQuestion(state => state.filter(i => i.id == res.data.question.id)?.length ? state : [res.data.question, ...state]),toggleVisibility())).catch(e => swal.error(e.response ? e.response?.data?.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const homeworkData = {
            title: formData.get('title'),
            description: formData.get('description'),
            submission_date: formData.get('submission_date'),
            total_marks: formData.get('total_marks'),
            created_by: userData.id,
            creator_name: `${userData.first_name} ${userData.last_name}`,
            for_class_id: class_id,
            question_data: fetchQuestion,
        };
        dispatch(setPreloader({ loader: true, message: "Please wait ..." }));
        homework.add(homeworkData)
            .then(res => { swal.success("success", res.message);toggleModal() })
            .catch(e => swal.error('Error', e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: "" })));
    };
    const removeSelectedQuestion = (question) => setAllQuestion(state => state.filter(s => s.id !== question.id));
    const columns = useMemo(() => [
        { Header: "SrNo", accessor: "id", Cell: cell => { return (<p className="text-success fs-4 mt-3"><i className="bx bx-checkbox-checked" /></p>) }, HeaderClass: 'text-center', DataClass: 'text-center' },
        { Header: "Question", Cell: cell => <div style={{ minWidth: '300px' }}>{cell.row.original.question}</div> },
        {
            Header: "Action",
            HeaderClass: 'text-center',
            DataClass: 'text-center',
            Cell: (cell) => (<Button onClick={e => removeSelectedQuestion(cell.row.original)} className="btn btn-sm btn-soft-danger me-1"><i className="bx bx-x fs-6" /></Button>)
        },
        {
            Header: 'List',
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => {
                var srn = 1;
                return (
                    <div className="d-flex">
                        <div className="flex-grow-1" >
                            <h5 className="fs-13 mb-1">
                                {/* <a href="#" className="link text-dark">{srn+=1}.</a> */}
                                <a href="#">{row.question}</a>
                            </h5>
                        </div>
                        <div className="flex-shrink-0">
                            <div>
                                <Button onClick={() => removeSelectedQuestion(row)} className="btn btn-sm btn-soft-danger me-1">
                                    <i className="ri-delete-bin-fill" />
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
        }

    ], [fetchQuestion]);
    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success add-btn waves-effect'>
                <i className="ri-add-line align-bottom me-1"></i>
                <span>Create Home Work</span>
            </button>

            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Create Home Work</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="row g-3">
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">Title</label>
                                    <input type="text" className="form-control" id="title" name="title" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label htmlFor="submission_date" className="form-label">Submission Date</label>
                                    <input type="date" className="form-control" id="submission_date" name="submission_date" />
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <span onClick={(e) => { e.preventDefault(); toggleVisibility(); }}> <span>{isVisible ? <button className="btn btn-soft-danger">- Close Question</button> : <button className="btn btn-soft-success">+ Add Question</button>}</span> </span>
                                <FetchQuestion setAllQuestion={setAllQuestion} tableData={setTableData}/>
                            </div>
                            <div className={isVisible ? "d-block" : "d-none"} id='addquestion'>

                                    <div className='row' >
                                        <div className="col-6">
                                            <div>
                                                <label htmlFor="course_subject" className="form-label">Subject</label>
                                                <input type="text" className="form-control" id='course_subject' name="subject" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <Row>
                                                <Col className="text-center">
                                                    <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                                                        <label htmlFor="profile-img-file-input" className="cursor-pointer"> <img src={userProfile ? userProfile : dummyUser} htmlFor="profile-img-file-input" className=" avatar-lg  cursor" alt="" /></label>
                                                        <div className="avatar-xs ms-0 me-5 mb-2 rounded-circle profile-photo-edit" style={{ marginLeft: "31px" }}>
                                                            <input id="profile-img-file-input" type="file" name="image" className="profile-img-file-input" accept="image/png, image/jpeg, image/jpg" onChange={(e) => onSave(e.target.files[0])} defaultValue="" />
                                                        </div>
                                                        <div className="avatar-xs rounded-circle profile-photo-edit">
                                                            <div onClick={toggleWebcam} className="avatar-title rounded-circle bg-light text-body"><i className="ri-camera-fill"></i></div>
                                                        </div>
                                                    </div>
                                                    {webcam ? (<div className='position-relative top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}><Camera onSave={f => { onSave(f); toggleWebcam() }} onClose={() => toggleWebcam()} /></div>) : null}
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-12">
                                            <div>
                                                <label htmlFor="course_question" className="form-label">Question</label>
                                                <textarea id='course_question' name="question" className='form-control' rows={5} placeholder='Type Here..' ></textarea>

                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex align-items-center justify-content-center mt-2'>
                                        <button type="button" onClick={handleAddQuestion} className="btn btn-primary">Save</button>
                                    </div>
                               
                            </div>

                            {fetchQuestion.length > 0 && <TableResponsive showFilter={false} isPagination={false} columns={columns} data={fetchQuestion} showCustomOptionPage={false} noRecord="No Record List" />}
                            <hr className='mt-1' />
                            <div className="col-12">
                                <div className="mb-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <label htmlFor="description" className="form-label">Description</label>

                                    </div>
                                    <textarea className="form-control" id="description" name="description"></textarea>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}
