import React, { useEffect, useState } from 'react';
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal, Row, Col } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { course, family, fee, student } from '../../../helper/api_url';
import DataLoading from '../../../components/DataLoading';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useNavigate } from 'react-router-dom';

function ViewFeeDetailsModal({className=''}) {
    const navigate = useNavigate();
    const { status, toggleModal } = useModalHandler();
    const [isLoading, setIsLoading] = useState(true);
    const [students, setStudents] = useState([]);
    const [courses, setCourses] = useState([]);


    const dispatch = useDispatch();
    useEffect(() => {
        if (status) {
            course.list().then(res => setCourses(res.data.course))
                .catch(e => e.response ? e.response.message : e.message)
                .finally(() => setIsLoading(false));
        }
    }, [status]);
    const handleViewFeeDetails = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: true, message: 'please wait ... ' }));
        const formData = new FormData(e.target);
        const student_id = formData.get('student_id');
        navigate(`/student/${student_id}/fee`)       
    }
    const fetchCourseStudentList = e => {
        setStudents([]);
        const course_id = e.value;
        course.students(course_id).then(res => setStudents(res.data.student))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
    }
    return (
        <>
            <button onClick={toggleModal} className={`btn btn-soft-info ${className}`}><i className="ri-eye-fill align-bottom me-1"></i>View Fee Details</button>
            <Modal className="fade" size='md' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>View Fee Details</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleViewFeeDetails}>
                    <Modal.Body>
                        {isLoading ? (<DataLoading />) : (
                            <>
                                <Row>
                                    <Col lg={12} className="mb-4">
                                        <div>
                                            <label className='control-label'>Select A Class</label>
                                            <CustomSelect
                                                onChange={fetchCourseStudentList}
                                                isSearchable
                                                options={courses.map(c => ({ label: c.name, value: c.id }))}
                                                placeholder="select Class"
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col lg={12} className="mb-4">
                                        <div>
                                            <label className='control-label'>Select A Student</label>
                                            <CustomSelect 
                                                name="student_id"
                                                options={students.map(s => ({
                                                    label: `#${s.id} - ${s.first_name} ${s.last_name}`,
                                                    value: s.id
                                                }))}
                                                placeholder="select student"
                                                isSearchable
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-soft-info btn-label right ms-auto" >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> View Fee Details
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ViewFeeDetailsModal
