import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { ASSET_URL, fee, invoice } from '../../../../helper/api_url';
import BreadCrumb from '../../../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import ImposeFeeModal from '../ImposeFeeModal';
import { TableResponsive } from '../../../../components/common/TableResponsive';
import FamilyDuesFeesPaymentModal from './familyDuesFeesPaymentModal';
import CollectionTabs from '../CollectionTabs';

export function convertDuesData(data) {
    const groupedData = {};
    data.forEach(user => {
        if (!groupedData[user.family.id]) {
            groupedData[user.family.id] = {
                family_id: user.family.id,
                father_name: user.family.father_name,
                father_mobile: user.family.father_mobile,
                father_photo: user.family.father_photo,
                mother_name: user.family.mother_name,
                mother_mobile: user.family.mother_mobile,
                mother_photo: user.family.mother_photo,
                total_dues: 0,
                no_of_pending_fees:0,
                no_of_students:0,
                dues: []
            };
        }
        user.due_fee?.forEach(due => {
            const totalAmount = parseInt(due.total_installments) * parseFloat(due.imposed_amount);
            const totalPaidAmount = due.fees_payment.reduce((t, c) => t + parseFloat(c.paid_amt), 0);
            const calculatedDues = totalAmount - totalPaidAmount;
            groupedData[user.family.id].dues.push({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                courses: user.courses.find(c => c.pivot.status === 'active')?.name || 'not Enrolled',
                dues_amount: calculatedDues,
                ...due
            });
            groupedData[user.family.id].total_dues += calculatedDues;
            groupedData[user.family.id].no_of_pending_fees ++;
        });
        groupedData[user.family.id].no_of_students ++;

    });
    return Object.values(groupedData).filter(d=>d.dues.length>0);
}
function FamilyDues() {
    const [transactionHistory,setTransactionHistory] = useState(null);
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['familydues'],
        queryFn: fee.dueslist,
        staleTime: 5 * 60 * 100, 
        select: (data) => convertDuesData(data.data.dues)
    });
    useEffect(()=>{
        invoice.transactionHistory().then(res=>setTransactionHistory(res.data.transaction_history));
    },[data])
    const columns = useMemo(()=>[
        {
            Header: "Father",
            Cell:(cell)=> {
                const imageUrl = ASSET_URL + cell.row.original.father_photo;
                return (<span> 
                    <img className="me-2 rounded-circle header-profile-user" 
                    style={{objectFit:'cover'}} 
                    src={imageUrl} alt="User Avatar" />{cell.row.original.father_name}</span>)
            }
        },
        {
            Header: "Father Name",
            DataClass:'text-center d-none',
            HeaderClass:'text-center d-none',
            accessor: "father_name",
        },
        {
            Header: "Phone",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor: "father_mobile",
        },
        {
            Header: "Students",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor: "no_of_students",
        },
        {
            Header: "No of Dues",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor: "no_of_pending_fees",
        },
        {
            Header: "Total Dues",
            DataClass:'text-center',
            HeaderClass:'text-center',
            accessor: "total_dues",
        },
        {
            Header: "Action",
            DataClass:'text-center',
            HeaderClass:'text-center',
            Cell:(cell)=> {
                const row = cell.row.original;
                return <FamilyDuesFeesPaymentModal row_data={row} />
            }
        },
        {
            Header: "List",
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl = ASSET_URL + row.father_photo;
                return (
                    <div className='p-2 d-flex align-items-center gap-2 justify-content-between'>
                        <div className="d-flex align-items-center gap-2">
                            <img 
                                className="me-2 rounded-circle header-profile-user" 
                                style={{objectFit:'cover'}} 
                                src={imageUrl} alt="User Avatar" 
                            />
                            <div className='flex-grow-1'>
                                <h5 className='mb-0'>{row.father_name}</h5>
                                <p className='mb-0'>{row.father_mobile}</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-4">
                            <div className="flex-grow-1 text-end">
                                <p className='mb-0'>{row.no_of_students} Students</p>
                                <p className='mb-0'>{row.no_of_pending_fees} Dues</p>
                                <p className='mb-0'>{row.total_dues} Rs</p>
                            </div>
                            <FamilyDuesFeesPaymentModal row_data={row} />
                        </div>
                    </div>
                )
            }
        },
    ],[data])
    return (
        <>
            <BreadCrumb title="Dues List" prevPage="Home" prevPath="/dashboard" />
            <Row>
                {transactionHistory != null && (
                    <CollectionTabs transactionHistory={transactionHistory} />
                )}
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Dues List</h5>
                            <ImposeFeeModal />
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={isLoading} columns={columns} data={data || []} noRecord="No Records in Dues List" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default FamilyDues