import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col, Card, CardBody } from 'react-bootstrap'
import { useModalHandler } from '../../../helper/custom_hook';
import { useDispatch } from "react-redux";
import CustomSelect from '../../../components/CustomSelect';
import { fee } from '../../../helper/api_url';
import { swal } from '../../../helper/swal';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useNavigate } from 'react-router-dom';
import { current_dues, given_fee_current_dues } from '../../../helper/dues_calculators';
import { handleViewInvoice } from '../../../helper/view-invoice';

function FeesPaymentModal({ row_data, setDuesData , setTransactionHistory}) {
    const duesData = row_data.due_fee.filter(dues =>((dues.imposed_amount * dues.total_installments) - (dues?.fees_payment?.reduce((acc, f) => acc + f?.paid_amt, 0))) > 0)
    const totalDues = duesData.reduce((acc, d) => acc + given_fee_current_dues(d), 0);
    const { status, toggleModal } = useModalHandler();
    const [discountAmt,setDiscountAmt] = useState();
    const [totalPayableAmt,setTotalPayableAmt] = useState(totalDues);
    const dispatch = useDispatch();
    useEffect(() => {
        let totalPayable = totalPayableAmt;
        duesData.forEach(dues => {
            const deposit_input = document.getElementById('deposit_' + dues.id);
            const deposit_input_sm = document.getElementById('deposit_sm' + dues.id);
            if(deposit_input){
                if(totalPayable > given_fee_current_dues(dues)){
                    deposit_input.value = given_fee_current_dues(dues);
                    deposit_input_sm.value = given_fee_current_dues(dues);
                    totalPayable -= given_fee_current_dues(dues);
                }
                else{
                    deposit_input.value = totalPayable;
                    deposit_input_sm.value = totalPayable;
                    totalPayable = 0;
                }
            }
        })
    }, [totalPayableAmt])

    const handlePaymentSubmit = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const discount = parseFloat(discountAmt);
        const paymentData = {
            payment_mode: formData.get('payment_mode'),
            user_id: row_data?.id,
            payment_details: row_data?.due_fee?.map(d => ({ due_fee_id: d.id, paid_amt: document.getElementById('deposit_' + d.id)?.value || 0 })),
            discount: parseFloat(discount || 0)
        }
        fee.payment(paymentData)
            .then(r => {
                setDuesData(s => s.map(f => f.id == r.data.fee_dues.id ? r.data.fee_dues : f));
                swal.success(r.message, 'Done');
                toggleModal();
                setTransactionHistory(r.data.transaction_history)
                return r.data
            })
            .then(data => handleViewInvoice(data?.invoice?.id))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))

    }
    
    const tableColumns = useMemo(() => [
        {
            Header: "Name",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            accessor: 'name'
        },
        {
            Header: "Amount",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return <span className="badge p-2 badge-outline-warning">{row.imposed_amount}</span>
            }
        },
        {
            Header: "Total paid",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return <span>{row.fees_payment.reduce((acc, f) => acc + f?.paid_amt, 0)}</span>
            }
        },
        {
            Header: "Current Dues",
            DataClass: 'text-center bg-soft-danger',
            HeaderClass: 'text-center bg-soft-danger',
            Cell: cell => {
                const row = cell.row.original;
                return <span>{given_fee_current_dues(row)}</span>
            }
        },
        {
            Header: "Payment",
            DataClass: 'text-center',
            HeaderClass: 'text-center',
            Cell: cell => {
                const row = cell.row.original;
                return <div className='d-flex align-items-center justify-content-center'>
                    <input
                        type='number'
                        id={'deposit_' + row.id}
                        step={0.01}
                        defaultValue={given_fee_current_dues(row)}
                        readOnly
                        className='form-control deposit_input d-none d-lg-block'
                        style={{ width: '120px' }}
                    />
                </div>
            }
        },
        {
            Header: "List",
            HeaderClass: 'd-none',
            DataClass: 'd-none',
            list: (row) => null
        }
    ])
    return (
        <>
            <button onClick={toggleModal} className='btn btn-sm btn-soft-success d-inline-flex align-items-center me-2'><i className="ri-secure-payment-fill fs-5 me-2" />Pay</button>
            <Modal className="fade" size='lg' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Dues payment - {row_data?.first_name} {row_data?.last_name}</h5>
                    </Modal.Title>
                </Modal.Header>
                <form className='d-none d-lg-block' onSubmit={handlePaymentSubmit}>
                    <Modal.Body>
                        <TableResponsive
                            data={duesData}
                            columns={tableColumns}
                            isPagination={false}
                            showFilter={false}
                            showCustomOptionPage={false}
                            isShowingPageLength={false}
                        />
                        <Row>
                            <Col xs={3} className='d-none d-lg-block'>
                                <label htmlFor="" className='control-label'>Total payable Amount</label>
                                <input 
                                    className='form-control' 
                                    value={totalPayableAmt} 
                                    id='total_payable_amount' 
                                    onChange={e=>setTotalPayableAmt(e.target.value>totalDues?totalDues:e.target.value)} 
                                    disabled={duesData.length === 0}
                                />
                            </Col>
                            <Col xs={3}>
                                <label htmlFor="" className='control-label'>Discount</label>
                                <input onChange={(e)=>setDiscountAmt(e.target.value)} type='number' step={0.01} className='form-control' id='discount_amt' />
                            </Col>
                            <Col xs={2}>
                                <label htmlFor="" className='control-label text-success'>Final Amount</label>
                                <input className='form-control bg-soft-success' value={(totalPayableAmt - (discountAmt || 0)) > 0 ? (totalPayableAmt - (discountAmt || 0)) : 0} id='final_amount' disabled />
                            </Col>
                            <Col xs={4}>
                                <label htmlFor="" className='control-label'>Payment Mode</label>
                                <CustomSelect name="payment_mode" options={[
                                    { label: 'Cash', value: 'cash' },
                                    { label: 'UPI', value: 'upi' },
                                    { label: 'Online (NEFT,RTGS,Online banking)', value: 'online' },
                                    { label: 'Cheque', value: 'cheque' },
                                    { label: 'Other', value: 'other' },
                                ]} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='submit' className='btn btn-success'>pay</button>
                    </Modal.Footer>
                </form>
                <form className='d-block d-lg-none' onSubmit={handlePaymentSubmit}>
                    <Modal.Body>
                        <div
                            className="d-flex flex-column gap-2 overflow-auto"
                            style={{
                                height: "50vh",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                                "&::WebkitScrollbar": { display: "none" }
                            }}
                        >
                            {row_data.due_fee
                                .filter(dues => ((dues.imposed_amount * dues.total_installments) - (dues?.fees_payment?.reduce((acc, f) => acc + f?.paid_amt, 0))) > 0)
                                .map((due, index) => (
                                    <Card key={index} className="border-0 shadow-sm mb-2">
                                        <CardBody>
                                            {/* Fee Details */}
                                            <div className="row g-3">
                                                <Col xs={12}>
                                                    <div className="bg-light rounded py-2 px-3">
                                                        <small className="text-muted d-block">Fee Name</small>
                                                        <span className="fw-medium">{due.name}</span>
                                                    </div>
                                                </Col>
                                                <div className="col-4">
                                                    <div className="bg-light rounded py-2 px-3">
                                                        <small className="text-muted d-block">Total Amount</small>
                                                        <span className="fw-medium">₹{due.imposed_amount}</span>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="bg-soft-success rounded py-2 px-3">
                                                        <small className="text-muted d-block">Paid Amount</small>
                                                        <span className="fw-medium">₹{due.fees_payment.reduce((acc, f) => acc + f?.paid_amt, 0)}</span>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="bg-soft-danger rounded py-2 px-3">
                                                        <small className="text-muted d-block">Current Dues</small>
                                                        <span className="fw-medium">₹{given_fee_current_dues(due)}</span>
                                                    </div>
                                                </div>
                                                <Col xs={12}>
                                                    <input
                                                        type='number'
                                                        id={'deposit_sm' + due.id}
                                                        step={0.01}
                                                        defaultValue={given_fee_current_dues(due)}
                                                        readOnly
                                                        className='form-control deposit_input_sm d-lg-none'
                                                    />
                                                </Col>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Row className='p-3 rounded' style={{ boxShadow: '0px 0px 15px rgba(56, 65, 74, 0.15)' }}>
                            <Col xs={4} className='d-lg-none'>
                                <label htmlFor="" className='control-label'>Total payable</label>
                                <input 
                                    className='form-control' 
                                    value={totalPayableAmt} 
                                    id='total_payable_amount_sm' 
                                    onChange={e=>setTotalPayableAmt(e.target.value>totalDues?totalDues:e.target.value)} 
                                    disabled={duesData.length === 0}
                                />
                            </Col>
                            <Col xs={4}>
                                <label htmlFor="" className='control-label'>Discount</label>
                                <input onChange={e => setDiscountAmt(e.target.value)} type='number' step={0.01} className='form-control' id='discount_amt' />
                            </Col>
                            <Col xs={4}>
                                <label htmlFor="" className='control-label text-success'>Final Amount</label>
                                <input className='form-control bg-soft-success' value={(totalPayableAmt - (discountAmt || 0)) > 0 ? (totalPayableAmt - (discountAmt || 0)) : 0} id='final_amount' disabled />
                            </Col>
                            <Col xs={12} className='mt-2'>
                                <label htmlFor="" className='control-label'>Payment Mode</label>
                                <CustomSelect name="payment_mode" options={[
                                    { label: 'Cash', value: 'cash' },
                                    { label: 'UPI', value: 'upi' },
                                    { label: 'Online (NEFT,RTGS,Online banking)', value: 'online' },
                                    { label: 'Cheque', value: 'cheque' },
                                    { label: 'Other', value: 'other' },
                                ]} />
                            </Col>
                            <Col xs={12} className='mt-2 d-flex justify-content-end'>
                                <button type='submit' className='btn btn-success'>pay</button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default FeesPaymentModal