import React, { useMemo } from 'react'
import BreadCrumb from "../../../components/common/BreadCrumb"
import { Button, Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap'
import { TableResponsive } from '../../../components/common/TableResponsive'
import { useNavigate } from 'react-router-dom'
import { ASSET_URL, course, student } from '../../../helper/api_url'
import { usePageInitialtor } from '../../../helper/custom_hook'
import { handleAdmitCardViewByClass } from '../../../helper/view-admit-card'
import { ViewCourseModal } from '../Course/ViewCourseModal'

function AdmitCard() {
    const navigate = useNavigate();
    const {tableData,setTableData,tableLoading,apiHandler} = usePageInitialtor(course);
       const columns = useMemo(() => [
            { Header: "Class Name", accessor: "name" },
            { Header: "Code", accessor: "code", HeaderClass: 'text-center', DataClass: 'text-center' },
            { Header: "Type", accessor: "type", HeaderClass: 'text-center', DataClass: 'text-center' },        
            {
                Header: "Action",
                HeaderClass: 'text-center',
                DataClass: 'text-center',
                Cell: (cell) => {
                    return (
                        <div className="">
                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => handleAdmitCardViewByClass(cell.row.original.id)}
                            >
                               Print Admit card
                            </button>
                        </div>
                    )
                },
            },
            {
                Header: 'List',
                HeaderClass: 'd-none',
                DataClass: 'd-none',
                list: (row) => {
                    const bs_colors = ['primary', 'info', 'warning', 'danger', 'success', 'light', 'secondary'];
                    const badge_class = row.id <= 6 ? `badge-outline-${bs_colors[row.id]}` : `badge-S-${bs_colors[row.id % 6]}`;
                    return (
                        <div className="d-flex">
                            <div className="flex-grow-1" data-id="1">
                                <h5 className="fs-13 mb-1">
                                    <a href="#" className="link text-dark"></a>
                                    <ViewCourseModal view={row}>
                                        <a href="#">{row.name}</a>
                                    </ViewCourseModal>
                                    <span className={`badge ${badge_class} px-1 ms-1`} >{row.code}</span>
                                </h5>
                                <p className="text-muted mb-0">
                                    {row.type} | For : {row.semesters} Semester</p>
                            </div>
                            <div className="flex-shrink-0">
                                <div>
                                    <button
                                        className='btn btn-sm btn-primary'
                                        onClick={() => handleAdmitCardViewByClass(row.id)}
                                    >
                                        Print Admit Card
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        ]);
    const handleNavigateToPrintAdmitCard = id => navigate('/admit-card-print/'+id);
    const handleNavigateToPrintDemandBill = id => navigate('/demand-bill-print/'+id);
    return (
        <>
            <BreadCrumb title="Admit Cards" prevPage="Home" prevPath="/dashboard" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Admit Cards</h5>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record Found" />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AdmitCard