import React, { useEffect, useState } from 'react';
import { useModalHandler } from '../../../helper/custom_hook';
import { Modal, Row, Col } from 'react-bootstrap';
import CustomSelect from '../../../components/CustomSelect';
import { course, family, student } from '../../../helper/api_url';
import DataLoading from '../../../components/DataLoading';
import { swal } from '../../../helper/swal';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { useQuery, useQueryClient } from '@tanstack/react-query';


function ChangeFamilyModal({className=''}) {
    const { status, toggleModal } = useModalHandler();
    const [isLoading, setIsLoading] = useState(true);
    const [students, setStudents] = useState([]);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    // fetching and caching courses
    const {data:courses=[],isLoading:coursesLoading,isError:isCoursesError,error:coursesError} = useQuery({
        queryKey:['courses'],
        queryFn:()=>course.list(),
        select:(res)=>res.data.course,
        staleTime:20*60*1000,
        gcTime:20*60*1000
    })
    // fetching and caching families
    const {data:families=[],isLoading:familiesLoading,isError:isFamiliesError,error:familiesError} = useQuery({
        queryKey:['families'],
        queryFn:()=>family.getAll(),
        select:(res)=>res.data.family,
        staleTime:20*60*1000,
        gcTime:20*60*1000
    })
    // loading and error handling
    useEffect(() => {
        setIsLoading(coursesLoading || familiesLoading)
        if(isCoursesError || isFamiliesError){
            const error = coursesError.response ? coursesError.response.data.message : coursesError.message 
            + ' | ' + familiesError.response ? familiesError.response.data.message : familiesError.message
            swal.error(error)
        } 
    }, [courses,families]);
    // handle change family | calling change family api
    const handleChangeFamily = e => {
        e.preventDefault();
        dispatch(setPreloader({ loader: coursesLoading || familiesLoading, message: 'please wait ... ' }));
        const formData = new FormData(e.target);
        family.change(formData).then(r => {
            swal.success(r.message, 'Done');
            // refetching families to update the cache
            queryClient.invalidateQueries({ queryKey: ['families'] });
        })
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
            .finally(() => dispatch(setPreloader({ loader: false, message: '' })))

    }
    // fetching student list related to selected course
    const fetchCourseStudentList = e => {
        setStudents([]);
        const course_id = e.value;
        course.students(course_id).then(res => setStudents(res.data.student))
            .catch(e => swal.error(e.response ? e.response.data.message : e.message))
    }
    return (
        <>
            <button onClick={toggleModal} className={`btn btn-soft-warning ${className}`}><i className="bx bx-cog align-middle me-1"></i>Change Family</button>
            <Modal className="fade" size='md' centered={true} backdrop="static" show={status} onHide={toggleModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Change or Assign A New Family</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleChangeFamily}>
                    <Modal.Body>
                        {isLoading ? (<DataLoading />) : (
                            <>
                                <Row>
                                    <Col lg={12} className="mb-4">
                                        <div>
                                            <label className='control-label'>Select A Class</label>
                                            <CustomSelect
                                                onChange={fetchCourseStudentList}
                                                isSearchable
                                                options={courses.map(c => ({ label: c.name, value: c.id }))}
                                                placeholder="select Class"
                                            />
                                        </div>
                                    </Col>
                                    
                                    <Col lg={12} className="mb-4">
                                        <div>
                                            <label className='control-label'>Select A Student</label>
                                            <CustomSelect 
                                                name="student_id"
                                                options={students.map(s => ({
                                                    label: `#${s.id} - ${s.first_name} ${s.last_name}`,
                                                    value: s.id
                                                }))}
                                                placeholder="select student"
                                                isSearchable
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} className='mb-4' >
                                        <div>
                                            <label className='control-label'>Select/Search A Family</label>
                                            <CustomSelect 
                                                name="family_id" 
                                                options={families.map(f=>({label:`${f?.father_name} (${f?.father_mobile})`,value:f.id}))} 
                                                isSearchable 
                                                placeholder='search family by phone number'
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-soft-info btn-label right ms-auto" >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Change / Save
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default ChangeFamilyModal
