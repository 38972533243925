import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, CardHeader, CardBody, Container, Modal, Tab, Nav, } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../../../components/common/BreadCrumb";
import { useModalHandler, useWebCam } from "../../../../../helper/custom_hook";
import { ASSET_URL, student } from "../../../../../helper/api_url";
import CustomSelect from "../../../../../components/CustomSelect";
import dummyUser from "../../../../../assets/images/users/user-dummy-img.jpg"
import Camera from '../../../../../components/common/Camera';
function EditAdmission() {
  const dispatch = useDispatch();
  const { admissionId } = useParams();
  const [admissionData, setAdmissionData] = useState();
  const [activeStep, setActiveStep] = useState(1);
  const { genders, status, toggleModal, userProfile, setUserProfile } = useModalHandler();
  const { webcam, toggleWebcam, setCapturedImageToInput } = useWebCam(setUserProfile);
  const onSave = f => { setCapturedImageToInput(f, document.getElementById('profile-img-file-input')) }
  useEffect(() => {
    student.profile(admissionId).then(r => { setAdmissionData(r.data.profile); setUserProfile(ASSET_URL + r.data.profile.avatar) }).catch(e => console.log(e));
  }, [admissionId])
  return (
    <>
      <BreadCrumb title="Edit Addmission" prevPage="Home" prevPath="/dashboard" />
      <Row>
        <Card>
          <Col xs={12}>
            <CardHeader className="d-flex align-items-center justify-content-between">
              <h5 className="card-title mb-0">Edit Addmission</h5>
              <div>
                <Link to="/admission" className='btn btn-soft-dark add-btn waves-effect me-2'><i className="ri-contacts-fill me-2"></i>Back</Link>
              </div>
            </CardHeader>
            <CardBody>
              <Row className="g-3 mx-lg-5">
                <div id="custom-progress-bar" className="progress-nav mb-4 mx-0">
                  <div className="progress" style={{ height: "1px" }}>
                    <div className="progress-bar" role="progressbar" style={{ width: "0%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <Nav variant="pills" className="px-2">
                    <Nav.Item ><Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 1 ? 'active' : ''}`} onClick={() => setActiveStep(1)} id="activeStep1">1</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 2 ? 'active' : ''}`} onClick={() => setActiveStep(2)} id="activeStep2">2</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 3 ? 'active' : ''}`} onClick={() => setActiveStep(3)} id="activeStep3">3</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 4 ? 'active' : ''}`} onClick={() => setActiveStep(4)} id="activeStep4">4</Nav.Link></Nav.Item>
                    <Nav.Item > <Nav.Link className={`rounded-pill d-flex justify-content-center align-items-center ${activeStep === 5 ? 'active' : ''}`} onClick={() => setActiveStep(5)} id="activeStep5">5</Nav.Link></Nav.Item>
                  </Nav>
                </div>
                <Tab.Container activeKey={activeStep}>
                  <form>
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <div>
                          <div className="mb-4">
                            <h5 className="mb-1 text-center text-primary">Personal Information</h5>
                          </div>
                        </div>
                        <Row>
                          <Col className="text-center">
                            <div className="profile-user position-relative d-inline-block mx-auto mb-2">
                              <label htmlFor="profile-img-file-input" className="cursor-pointer"> <img src={userProfile ? userProfile : dummyUser} htmlFor="profile-img-file-input" className="rounded-circle avatar-lg img-thumbnail user-profile-image cursor" alt="" /></label>
                              <div className="avatar-xs ms-0 me-5 mb-2 rounded-circle profile-photo-edit" style={{ marginLeft: "31px" }}>
                                <input id="profile-img-file-input" type="file" name="avatar" className="profile-img-file-input" accept="image/png, image/jpeg, image/jpg" onChange={(e) => onSave(e.target.files[0])} defaultValue="" />
                              </div>
                              <div className="avatar-xs rounded-circle profile-photo-edit">
                                <div onClick={toggleWebcam} className="avatar-title rounded-circle bg-light text-body"><i className="ri-camera-fill"></i></div>
                              </div>
                            </div>
                            {webcam ? (<div className='position-relative top-0 bottom-0 d-flex justify-content-center align-items-center start-0 end-0 modal-content border-0' style={{ zIndex: 9 }}><Camera onSave={f => { onSave(f); toggleWebcam() }} onClose={() => toggleWebcam()} /></div>) : null}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <div className="col-6 mb-2">
                            <input type="hidden" name="user_id" defaultValue={admissionData?.id} />
                            <div>
                              <label htmlFor="firstName" className="form-label">First Name <span className="text-danger px-1">*</span></label>
                              <input type="text" className="form-control" id='firstName' name="first_name" defaultValue={admissionData?.first_name} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="last_name" className="form-label">Last Name <span className="text-danger px-1">*</span></label>
                              <input type="text" className="form-control" id='last_name' defaultValue={admissionData?.last_name} name="last_name" />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="gender" className="form-label">Gender <span className="text-danger px-1">*</span></label>
                              {admissionData ? <CustomSelect options={genders} defaultValue={{ value: admissionData?.gender, label: admissionData?.gender }} isSearchable name='gender' /> : ''}
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="dob" className="form-label">Date of Birth <span className="text-danger px-1">*</span></label>
                              <input type="date" className="form-control" id='dob' name="dob" defaultValue={admissionData?.dob} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="phone" className="form-label">Phone <span className="text-danger px-1">*</span></label>
                              <input type="text" className="form-control" id='phone' name="phone" defaultValue={admissionData?.phone} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="alternate_phone" className="form-label">Alternate Phone</label>
                              <input type="text" className="form-control" id='alternate_phone' name="alternate_phone" defaultValue={admissionData?.personal_details.phone} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="email" className="form-label">Email <span className="text-danger px-1">*</span></label>
                              <input type="email" className="form-control" id='email' name="email" defaultValue={admissionData?.email} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="alternate_email" className="form-label">Alternate Email</label>
                              <input type="email" className="form-control" id='alternate_email' name="alternate_email" defaultValue={admissionData?.personal_details.email} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="marital_status" className="form-label">Marital Status <span className="text-danger px-1">*</span></label>
                              {admissionData ? <CustomSelect isSearchable name="marital_status" defaultValue={{ value: admissionData?.personal_details.marital_status, label: admissionData?.personal_details.marital_status }} options={[
                                { value: 'unmarried', label: 'UnMarried' },
                                { value: 'married', label: 'Married' },
                                { value: 'widowed', label: 'widowed' },
                                { value: 'divorced', label: 'Divorced' },
                                { value: 'separated', label: 'Separated' },
                                { value: 'other', label: 'Other' },
                              ]} /> : ''}
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="adhaar" className="form-label">Adhar Number </label>
                              <input type="number" className="form-control" id='adhaar' name="adhaar" defaultValue={admissionData?.personal_details.adhaar} />
                            </div>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button type="button" onClick={() => setActiveStep(2)} className="btn btn-primary btn-label right ms-auto nexttab nexttab" ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>update & Next</button>
                          </div>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <Row>
                          <div className="mb-4"><h5 className="mb-1 text-center text-primary">Other Information</h5></div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="father_name" className="form-label">Father Name <span className="text-danger px-1">*</span></label>
                              <input type="text" className="form-control" id='father_name' name="father_name" defaultValue={admissionData?.personal_details.father_name} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="mother_name" className="form-label">Mother Name <span className="text-danger px-1">*</span></label>
                              <input type="text" className="form-control" id='mother_name' name="mother_name" defaultValue={admissionData?.personal_details.mother_name} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="guardian_name" className="form-label">Guardian Name</label>
                              <input type="text" className="form-control" id='guardian_name' defaultValue={admissionData?.personal_details.guardian_name} name="guardian_name" />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="occupation" className="form-label">Occupation </label>
                              <input type="text" className="form-control" id='occupation' name="occupation" defaultValue={admissionData?.personal_details.occupation} />
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="handicapped" className="form-label">Handicapped</label>
                              <select name="handicapped" id="handicapped" defaultValue={admissionData?.personal_details.handicapped} className="form-control">
                                <option value="0">NO</option>
                                <option value="1">Yes</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="ex_serviceman" className="form-label">Ex-Serviceman</label>
                              <select name="ex_serviceman" id="ex_serviceman" defaultValue={admissionData?.personal_details.ex_serviceman} className="form-control">
                                <option value="0">NO</option>
                                <option value="1">Yes</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="ews" className="form-label">Ews</label>
                              <select name="ews" id="ews" defaultValue={admissionData?.personal_details.ews} className="form-control">
                                <option value="0">NO</option>
                                <option value="1">Yes</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-2">
                            <div>
                              <label htmlFor="category" className="form-label">Category <span className="text-danger px-1">*</span></label>
                              {admissionData ? <CustomSelect name="category" defaultValue={{ value: admissionData?.personal_details.category, label: admissionData?.personal_details.category }} isSearchable options={[
                                { label: 'Genral', value: 'gen' },
                                { label: 'OBC', value: 'obc' },
                                { label: 'Backward Class', value: 'bc' },
                                { label: 'SC', value: 'sc' },
                                { label: 'ST', value: 'st' },
                                { label: 'other', value: 'other' },
                              ]} />
                                : ''}
                            </div>
                          </div>
                          <div className="d-flex align-items-start gap-3 mt-4">
                            <button type="submit" className={`btn btn-primary btn-label right ms-auto nexttab nexttab}`} ><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Update & Next</button>
                          </div>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </form>
                  <Tab.Content>
                    {/* <Tab.Pane eventKey={3}><EditAddress admissionData={admissionData} /></Tab.Pane>
                                            <Tab.Pane eventKey={4}>{admissionData?<EditQualification documents={admissionData?.documents} qualifications={admissionData?.qualifications} />:''}</Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </Row>
            </CardBody>
          </Col>
        </Card>
      </Row>
    </>
  );
}

export default EditAdmission;
