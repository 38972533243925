import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { allUser, course, examination } from '../../../../../helper/api_url';
import { useModalHandler } from '../../../../../helper/custom_hook';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../../../features/Ui/uiSlice';
import { swal } from '../../../../../helper/swal';
import CustomSelect from '../../../../../components/CustomSelect';
import Swal from 'sweetalert2';

export function AddQuestionModal({ setTableData }) {
    const { toggleModal, status } = useModalHandler();
    const [courseOption, setCourseOption] = useState([])

    const dispatch = useDispatch()
     useEffect(() => {
            course.list()
                .then(res => setCourseOption(res.data.course?.map(item => ({ value: item.id, label: item.name }))))
                .catch(err => swal.error(err.response?.data?.message || err.message));
        }, []);
    const handleSubmit=(e)=>{
        e.preventDefault()
        const formData = new FormData(e.target)
        dispatch(setPreloader({loader:true,message:'adding Question please wait ..'}))
        examination.createQuestion(formData)
        .then(res => { setTableData(state => state.filter(i => i.id == res.data.question.id)?.length ? state : [res.data.question, ...state]); toggleModal(); Swal.fire(res.message) }).catch(e=>swal.error(e.response?e.response?.data?.message:e.message))
        .finally(()=>dispatch(setPreloader({loader:false,message:''})))
    }

    return (
        <>
            <button onClick={toggleModal} className='btn btn-soft-success'>+ Create Question</button>
            <Modal className="fade" centered={true} backdrop="static" show={status} onHide={toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title><h5>Add New Question</h5></Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="row g-3">
                            <div className="col-12">
                                <div>
                                    <label htmlFor="class_id" className="form-label">Class</label>
                                    <CustomSelect options={courseOption} placeholder="Select a class..." name="class_id" />
                                </div>
                            </div>  
                            <div className="col-6">
                                <div>
                                    <label htmlFor="course_subject" className="form-label">Subject</label>
                                    <input type="text" className="form-control" id='course_subject' name="subject"   />
                                </div>
                            </div>   
                            <div className="col-6">
                                <div>
                                    <label htmlFor="image" className="form-label">image</label>
                                   <input type="file" className='form-control' name='image' />
                                </div>
                            </div>  
                            <div className="col-12">
                                <div>
                                    <label htmlFor="course_question" className="form-label">Question</label>
                                    <textarea id='course_question' name="question" className='form-control' rows={5} placeholder='Type Here..' ></textarea>
                                   
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-lg-12">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={toggleModal}>Close</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
